<template>
    <div id="viewer"></div>
</template>

<script>
    import * as Three from 'three'
    import {CSS3DObject, CSS3DRenderer} from "three/examples/jsm/renderers/CSS3DRenderer";
    import {getGraphImagesByClass} from "../api/homeService";

    export default {
        name: "vr",
        data() {
            return {
                camera: {}, //相机
                scene: {}, //场景
                renderer: {}, //渲染器
                touchX: '', touchY: '',
                lon: 90, lat: 0,
                phi: 0, theta: 0,
                target: {},
                zrzyId: '',
                sides: [],
            }
        },
        mounted() {

        },
        methods: {
            setData(zrzyId) {
                this.zrzyId = zrzyId;
                this.getGraphImage()
            },
            /**
             * 获取图片
             */
            getGraphImage() {
                const data = {twmbId: this.zrzyId, twlx: 'qjt'}
                getGraphImagesByClass(data).then(res => {
                        const images = res.data
                        if (images == null || images.length === 0) {
                            this.$message.warning("没有找到相应图片")
                            return
                        }
                        const side = []
                        for (let i = 0; i < images.length; i++) {
                            if (images[i].twFqlx === 'up') {
                                side.push({
                                    url: images[i].ydlj,
                                    position: [0, 512, 0],
                                    rotation: [Math.PI / 2, 0, Math.PI]
                                })
                            } else if (images[i].twFqlx === 'east') {
                                side.push({
                                    url: images[i].ydlj,
                                    position: [512, 0, 0],
                                    rotation: [0, -Math.PI / 2, 0]
                                })
                            } else if (images[i].twFqlx === 'south') {
                                side.push({
                                    url: images[i].ydlj,
                                    position: [0, 0, 512],
                                    rotation: [0, Math.PI, 0]
                                })
                            } else if (images[i].twFqlx === 'west') {
                                side.push({
                                    url: images[i].ydlj,
                                    position: [-512, 0, 0],
                                    rotation: [0, Math.PI / 2, 0]
                                })
                            } else if (images[i].twFqlx === 'north') {
                                side.push({
                                    url: images[i].ydlj,
                                    position: [0, 0, -512],
                                    rotation: [0, 0, 0]
                                })
                            } else if (images[i].twFqlx === 'below') {
                                side.push({
                                    url: images[i].ydlj,
                                    position: [0, -512, 0],
                                    rotation: [-Math.PI / 2, 0, Math.PI]
                                })
                            }
                        }
                        this.sides = side
                        this.init();
                        this.animate();
                    }
                )
            },


            init() {
                let container = document.getElementById('viewer')
                this.camera = new Three.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
                this.target = new Three.Vector3(0, 0, 0);
                this.scene = new Three.Scene();


                for (let i = 0; i < this.sides.length; i++) {
                    let side = this.sides[i];
                    let element = document.createElement('img');
                    element.width = 1026; // 2 pixels extra to close the gap.
                    element.src = side.url;
                    let object = new CSS3DObject(element);
                    object.position.fromArray(side.position);
                    object.rotation.fromArray(side.rotation);
                    this.scene.add(object);
                }
                //创建渲染器
                this.renderer = new CSS3DRenderer();
                //设置渲染器大小
                this.renderer.setSize(window.innerWidth - 50, window.innerHeight - 90);

                container.appendChild(this.renderer.domElement);

                //注册监听事件
                container.addEventListener('mousedown', this.onDocumentMouseDown, false);
                container.addEventListener('wheel', this.onDocumentMouseWheel, false);
                container.addEventListener('touchstart', this.onDocumentTouchStart, false);
                container.addEventListener('touchmove', this.onDocumentTouchMove, false);
                container.addEventListener('resize', this.onWindowResize, false);
            }
            ,
            /**
             * 窗口大小
             */
            onWindowResize() {

                this.camera.aspect = window.innerWidth / window.innerHeight;
                this.camera.updateProjectionMatrix();

                this.renderer.setSize(window.innerWidth, window.innerHeight);

            }
            ,
            /**
             * 鼠标按下
             **/
            onDocumentMouseDown(event) {

                event.preventDefault();

                document.addEventListener('mousemove', this.onDocumentMouseMove, false);
                document.addEventListener('mouseup', this.onDocumentMouseUp, false);

            }
            ,
            /**
             * 鼠标移动
             * */
            onDocumentMouseMove(event) {

                let movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
                let movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

                this.lon -= movementX * 0.1;
                this.lat += movementY * 0.1;
                this.cameraLocation();
            }
            ,
            /**
             * 鼠标松开
             * */
            onDocumentMouseUp() {
                document.removeEventListener('mousemove', this.onDocumentMouseMove);
                document.removeEventListener('mouseup', this.onDocumentMouseUp);
            }
            ,
            /**
             * 鼠标滚动
             * @param event
             */
            onDocumentMouseWheel(event) {

                const fov = this.camera.fov + event.deltaY * 0.05;

                this.camera.fov = Three.MathUtils.clamp(fov, 10, 75);

                this.camera.updateProjectionMatrix();
                this.cameraLocation();
            }
            ,
            /**
             * 触摸事件
             * @param event
             */
            onDocumentTouchStart(event) {

                event.preventDefault();

                let touch = event.touches[0];

                this.touchX = touch.screenX;
                this.touchY = touch.screenY;
                this.cameraLocation();
            }
            ,
            /**
             * 触摸离开
             * @param event
             */
            onDocumentTouchMove(event) {

                event.preventDefault();

                let touch = event.touches[0];

                this.lon -= (touch.screenX - this.touchX) * 0.1;
                this.lat += (touch.screenY - this.touchY) * 0.1;

                this.touchX = touch.screenX;
                this.touchY = touch.screenY;
                this.cameraLocation();
            }
            ,
            /**
             * 加载图片
             */
            animate() {
                // 保持动画
                // requestAnimationFrame(this.animate);
                //相机位置
                this.lon += 0.1;
                this.lat = Math.max(-85, Math.min(85, this.lat));
                this.cameraLocation()

            }
            ,
            /**
             * 相机位置
             */
            cameraLocation() {
                this.phi = Three.MathUtils.degToRad(90 - this.lat);
                this.theta = Three.MathUtils.degToRad(this.lon);
                this.target.x = Math.sin(this.phi) * Math.cos(this.theta);
                this.target.y = Math.cos(this.phi);
                this.target.z = Math.sin(this.phi) * Math.sin(this.theta);

                this.camera.lookAt(this.target);

                this.renderer.render(this.scene, this.camera);
            }
        }
    }
</script>

<style scoped>

</style>
